<template>
  <div>
    <Navbar />
    <loading
        id="loader"
        class="vl-parent"
        v-model:active="isLoading"
        :is-full-page="true"
        color="#007bff"
        :height=128
        :width=128
    />
    <div v-if="!isLoading" class="row justify-content-center create-build-form">
      <div class="col-5">
        <!-- Vertically centered modal -->
        <div class="modal fade" id="deployModal" tabindex="-1" aria-labelledby="deployModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="deployModalLabel">Modal title</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <p>You are going to deploy <b>{{ selected_options.ref }}</b> to <b>{{ selected_options.environment }}</b>! Are you sure you want to make a deployment?</p>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">No</button>
                <button
                    @click="createBuild"
                    id="buildDeployButton"
                    type="button"
                    class="btn btn-warning"
                    data-loading-text="<i class='fa fa-spinner fa-spin '></i> Creating build...">
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="mb-4">
          <label for="refSelect" class="form-label">Tag or Branch</label>
          <select v-model="selected_options.ref" id="refSelect" class="form-select" aria-label="Tag or Branch selector">
            <option v-for="ref in create_options.refs" v-bind:value="ref">{{ ref }}</option>
          </select>
        </div>

        <div class="mb-4">
          <label for="envSelect" class="form-label">Environment</label>
          <select v-model="selected_options.environment" id="envSelect" class="form-select" aria-label="Environment selector">
            <option v-for="environment in create_options.environments" v-bind:value="environment.name">{{ environment.friendly_name }}</option>
          </select>
        </div>

        <hr class="my-4">

        <label for="buildPluginOptions" class="form-label mb-4">Plugin options</label>
        <div id="buildPluginOptions" class="mb-4 form-check">
          <input v-model="selected_options.build_plugin" type="checkbox" class="form-check-input" id="buildPlugin">
          <label class="form-check-label" for="buildPlugin">Build plugin</label>
        </div>

        <div v-if="selected_options.build_plugin" class="mb-4">
          <label for="installerTypeSelect" class="form-label">Installer type</label>
          <select v-model="selected_options.installer_type" id="installerTypeSelect" class="form-select" aria-label="Installer type">
            <option value="msi">MSI</option>
            <option value="exe">EXE</option>
          </select>
        </div>

        <div v-if="selected_options.build_plugin" class="mb-4">
          <label for="installerTypeSelect" class="form-label">Additional variables</label>
          <div v-for="(variable, index) in selected_options.additional_variables" :key="index" class="mb-1 d-flex">
            <input type="text" class="form-control m-1" v-model="variable.key">
            <input type="text" class="form-control m-1" v-model="variable.value">
            <button class="btn btn-danger m-1" @click="removeVariable(index)">Remove</button>
          </div>
          <button value="Add variable" class="btn btn-primary m-1" @click="addVariable">Add variable</button>
        </div>

        <div v-if="selected_options.build_plugin" class="mb-4">
          <label for="configSelect" class="form-label">Plugin configuration</label>
          <select
              v-model="selected_options.configs"
              id="configSelect"
              class="form-select"
              aria-label="Configuration selector"
              multiple="multiple"
              v-bind:size="create_options.configs.length"
          >
            <option v-for="configuration in create_options.configs" v-bind:value="configuration">{{ configuration }}</option>
          </select>
        </div>

        <hr class="my-4">

        <label for="" class="form-label mb-4">Services build</label>
        <div id="" class="mb-4 form-check">
          <input @change="selectAllServices($event)" type="checkbox" class="form-check-input" id="allServices">
          <label class="form-check-label">Select all services</label>
        </div>

        <div v-for="service in create_options.services" id="" class="mb-4 form-check">
          <input
              type="checkbox"
              class="form-check-input"
              v-bind:id="'service-checkbox-' + service.id"
              v-bind:value="service"
              v-model="selected_options.services"
          >
          <label class="form-check-label" v-bind:for="'service-checkbox-' + service.id">{{ service.name }}</label>
        </div>

        <hr class="my-4">

        <label for="buildPluginOptions" class="form-label mb-4">Deploy options</label>
        <div class="mb-4 form-check">
          <input v-model="selected_options.deploy" type="checkbox" class="form-check-input" id="deployCheckbox">
          <label class="form-check-label" for="exampleCheck1">Deploy build</label>
        </div>

        <button
            v-on:click="checkBuildOptions"
            id="buildButton"
            type="button"
            class="btn btn-primary btn-lg mb-4 build-button"
            data-loading-text="<i class='fa fa-spinner fa-spin '></i> Creating build...">
          {{ button_text }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import { Modal } from 'bootstrap';
import axios from '@/services/axios-config.js';
import $ from 'jquery';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

export default {
  name: 'CreateBuild',
  components: {
    Navbar,
    Loading
  },
  data() {
    return {
      isLoading: true,
      modalInstance: null,
      create_options: {},
      selected_options: {
        ref: '',
        environment: '',
        configs: [],
        build_plugin: false,
        installer_type: 'msi',
        services: [],
        deploy: false,
        additional_variables: [
          {
            key: '',
            value: ''
          },
        ]
      }
    };
  },
  methods: {
    addVariable() {
      this.selected_options.additional_variables.push({
        key: '',
        value: ''
      });
    },
    removeVariable(index) {
      this.selected_options.additional_variables.splice(index, 1);
    },
    loadCreateOptions() {
      return axios.get('/create')
          .then((response) => {
            this.create_options = response.data.create_options;
          })
          .catch((error) => {
            console.error("Error occurred:", error);
          });
    },
    selectAllServices(event) {
      if (event.target.checked) {
        this.selected_options.services = this.create_options.services;
      } else {
        this.selected_options.services = [];
      }
    },
    checkBuildOptions() {
      const { ref, environment, build_plugin, configs, deploy, services } = this.selected_options;
      let errorMessages = [];

      if (errorMessages.length > 0) {
        alert(errorMessages[0]);
        return;
      }

      if (deploy) {
        this.modalInstance = new Modal(document.getElementById('deployModal'));
        this.modalInstance.show();
      } else {
        this.createBuild();
      }
    },
    createBuild() {
      let buttonId = this.selected_options.deploy ? '#buildDeployButton' : '#buildButton';
      $(buttonId).html("<i class='fa fa-spinner fa-spin '></i> Creating build...")
          .attr('disabled', true);

      axios.post('/builds', this.selected_options, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          const data = response.data;
          if (data.build_id) {
            this.modalInstance.hide();
            this.$router.push({ path: '/build/' + data.build_id });
          }
        })
        .catch((error) => {
          console.error("Error occurred:", error);
        });
    }
  },
  async beforeMount() {
    this.isLoading = true;
    await this.loadCreateOptions();
    this.isLoading = false;
  },
  computed: {
    button_text() {
      return this.selected_options.deploy ? 'Build & Deploy' : 'Build';
    }
  },
  }
</script>

<style scoped>
#loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.create-build-form {
  margin-top: 30px;
}
</style>
