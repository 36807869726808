<template>
  <div>
    <Navbar />
    <div class="row justify-content-center">
      <loading
          id="loader"
          class="vl-parent"
          v-model:active="isLoading"
          :is-full-page="false"
          color="#007bff"
          :height=128
          :width=128
      />
      <div class="col-auto">
        <table class="table table-builds">
          <thead v-if="builds.length > 0">
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Build Name</th>
            <th scope="col">Date</th>
            <th scope="col">User</th>
            <th scope="col">Environment</th>
            <th scope="col">Tag or Branch</th>
            <th scope="col">Artifacts</th>
            <th scope="col">Status</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="build in builds">
            <td><a v-bind:href="'/build/' + build.id">{{ build.id }}</a></td>
            <td><a v-bind:href="'/build/' + build.id">{{ build.name }}</a></td>
            <td>{{ build.date }}</td>
            <td>{{ build.user }}</td>
            <td><a target="_blank" v-bind:href="build.environment.link">{{ build.environment.name }}</a></td>
            <td><a target="_blank" v-bind:href="build.ref_link">{{ build.ref }}</a></td>
            <td><a target="_blank" v-bind:href="build.share_link">Storage</a></td>
            <td>
              <span v-if="build.status === 'created'" class="badge rounded-pill bg-warning">
                  In progress <span class="spinner-border" style="width: 12px; height: 12px;" role="status" aria-hidden="true"></span>
              </span>
              <span v-if="build.status === 'success'" class="badge rounded-pill bg-success">Success</span>
              <span v-if="build.status === 'failed'" class="badge rounded-pill bg-danger">Failed</span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import axios from '@/services/axios-config.js';
import Loading from "vue-loading-overlay";
import 'vue-loading-overlay/dist/css/index.css';

export default {
  name: 'Builds',
  components: {
    Loading,
    Navbar
  },
  data() {
    return {
      isLoading: false,
      builds: [],
    };
  },
  methods: {
    async loadBuildsList() {
      const response = await axios.get('/builds');
      this.builds = response.data.builds;
    }
  },
  async beforeMount(){
    this.isLoading = true;
    await this.loadBuildsList()
    this.isLoading = false;
  },
  mounted() {
    setInterval(() => {
      this.loadBuildsList()
    }, 5000)
  },
}
</script>

<style scoped>
#loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
