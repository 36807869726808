import {createRouter, createWebHistory} from 'vue-router'
import Login from '@/components/Login.vue'
import Builds from "@/components/Builds.vue";
import CreateBuild from "@/components/CreateBuild.vue";
import BuildDetails from "@/components/BuildDetails.vue";
import UploadLicense from "@/components/UploadLicense.vue";
import axios from '@/services/axios-config.js';
import store from "@/store/index.js";

const routes = [
    {
        path: '/',
        redirect: '/builds',
        meta: { title: 'Builds list' }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: { title: 'Login' }
    },
    {
        path: '/builds',
        name: 'Builds',
        component: Builds,
        meta: {
            requiresAuth: true,
            title: 'Builds list'
        }
    },
    {
        path: '/build/:id',
        name: 'BuildDetails',
        component: BuildDetails,
        props: true,
        meta: {
            requiresAuth: true,
            title: 'Build details'
        }
    },
    {
        path: '/create',
        name: 'CreateBuild',
        component: CreateBuild,
        meta: {
            requiresAuth: true,
            title: 'Create build'
        }
    },
    {
        path: '/upload/license',
        name: 'UploadLicense',
        component: UploadLicense,
        meta: {
            requiresAuth: true,
            title: 'Upload license'
        }
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach(async (to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        try {
            const response = await axios.get("/auth");
            if (response.status === 200 && response.data.is_authenticated) {
                await store.dispatch('authenticateUser', response.data);
                next();
            } else {
                console.log("User is not authenticated or unexpected response");
                next({ name: "Login" });
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.log("User is not authorized");
                next({ name: "Login" });
            } else if (error.response && error.response.status === 400) {
                console.log("Wrong credentials");
                next({ name: "Login" });
            } else {
                console.error("Error checking authentication:", error);
                next({ name: "Login" });
            }
        }
    } else {
        next();
    }

    // Setting the page title based on the route's meta information
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
    if (nearestWithTitle) {
        document.title = nearestWithTitle.meta.title;
    } else {
        document.title = 'Builds Tool';
    }

});

export default router;
