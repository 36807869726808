import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import axios from '@/services/axios-config.js';
import store from "@/store/index.js";

import 'bootstrap';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@/assets/css/style.css';

const app = createApp(App);

app.config.globalProperties.$http = axios;

app.use(router);
app.use(store);
app.mount('#app');
