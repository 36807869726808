<template>
  <div>
    <Navbar />
    <loading
        id="loader"
        class="vl-parent"
        v-model:active="isLoading"
        :is-full-page="true"
        color="#007bff"
        :height=128
        :width=128
    />
    <div v-if="buildDetails && buildDetails.environment" class="row justify-content-center build-details">
      <div class="col-9">
        <table class="table table-striped build-details-table">
          <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Build Name</th>
            <th scope="col">Date</th>
            <th scope="col">User</th>
            <th scope="col">Environment</th>
            <th scope="col">Tag or Branch</th>
            <th scope="col">Artifacts</th>
            <th scope="col">Status</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th scope="row">{{ buildDetails.id }}</th>
            <td>{{ buildDetails.name }}</td>
            <td>{{ buildDetails.date }}</td>
            <td>{{ buildDetails.user }}</td>
            <td><a target="_blank" v-bind:href="buildDetails.environment.link">{{ buildDetails.environment.name }}</a></td>
            <td>{{ buildDetails.ref }}</td>
            <td><a target="_blank" v-bind:href="buildDetails.share_link">Storage</a></td>
            <td>
              <span v-if="buildDetails.status === 'created'" class="badge rounded-pill bg-warning">
                  In progress <span class="spinner-border" style="width: 12px; height: 12px;" role="status" aria-hidden="true"></span>
              </span>
              <span v-if="buildDetails.status === 'success'" class="badge rounded-pill bg-success">Success</span>
              <span v-if="buildDetails.status === 'failed'" class="badge rounded-pill bg-danger">Failed</span>
            </td>
          </tr>
          </tbody>
        </table>

        <table class="table table-hover table-services">
          <thead>
          <tr>
            <th>Services</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
          <tr>
            <th>Build</th>
            <th>Date</th>
            <th>Pipeline</th>
            <th>Service</th>
            <th>Status</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="serviceEntry in buildDetails.services">
            <td class="w-50">{{ serviceEntry.build_name }}</td>
            <td>{{ serviceEntry.date }}</td>
            <td><a target="_blank" v-bind:href="serviceEntry.pipeline.link">{{ serviceEntry.pipeline.id }}</a></td>
            <td><a target="_blank" v-bind:href="serviceEntry.service.link">{{ serviceEntry.service.name }}</a></td>
            <td>
              <span v-if="serviceEntry.status === 'created'" class="badge rounded-pill bg-warning">
                  In progress <span class="spinner-border" style="width: 12px; height: 12px;" role="status" aria-hidden="true"></span>
              </span>
              <span v-if="serviceEntry.status === 'success'" class="badge rounded-pill bg-success">Success</span>
              <span v-if="serviceEntry.status === 'failed'" class="badge rounded-pill bg-danger">Failed</span>
            </td>
          </tr>
          </tbody>
        </table>

        <table class="table table-hover table-plugins">
          <thead>
          <tr>
            <th>Plugins</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
          <tr>
            <th>Build</th>
            <th>Date</th>
            <th>Pipeline</th>
            <th>Config</th>
            <th>Installer Type</th>
            <th>Status</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="pluginEntry in buildDetails.plugins">
            <td class="w-50">{{ pluginEntry.name }}</td>
            <td>{{ pluginEntry.date }}</td>
            <td><a target="_blank" v-bind:href="pluginEntry.pipeline.link">{{ pluginEntry.pipeline.id }}</a></td>
            <td><a target="_blank" v-bind:href="pluginEntry.config.link">{{ pluginEntry.config.name }}</a></td>
            <td>{{ pluginEntry.installer_type }}</td>
            <td>
              <span v-if="pluginEntry.status === 'created'" class="badge rounded-pill bg-warning">
                  In progress <span class="spinner-border" style="width: 12px; height: 12px;" role="status" aria-hidden="true"></span>
              </span>
              <span v-if="pluginEntry.status === 'success'" class="badge rounded-pill bg-success">Success</span>
              <span v-if="pluginEntry.status === 'failed'" class="badge rounded-pill bg-danger">Failed</span>
            </td>
          </tr>
          </tbody>
        </table>

        <table class="table table-hover table-artifacts">
          <thead>
          <tr>
            <th>Artifacts</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="artifactEntry in buildDetails.artifacts">
            <td class="w-50">{{ artifactEntry.name }}</td>
            <td>{{ artifactEntry.date }}</td>
            <td><a target="_blank" v-bind:href="artifactEntry.config.link">{{ artifactEntry.config.name }}</a></td>
            <td><a target="_blank" v-bind:href="artifactEntry.link">Storage</a></td>
            <td><a v-bind:href="artifactEntry.download_link">Download</a></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import axios from '@/services/axios-config.js';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

export default {
  name: 'Builds',
  components: {
    Navbar,
    Loading
  },
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      buildDetails: {}
    };
  },
  methods: {
    async loadBuildDetails() {
      const response = await axios.get('/build/' + this.id);
      this.buildDetails = response.data.build_details;
    }
  },
  async beforeMount() {
    this.isLoading = true;
    await this.loadBuildDetails()
    this.isLoading = false;
  },
  mounted() {
    setInterval(() => {
      this.loadBuildDetails()
    }, 5000)
  },
}
</script>
