<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark p-3" v-if="user">
    <div class="container-fluid">
      <a class="navbar-brand" href="/">Builds Admin Panel</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav ms-auto">
          <li class="nav-item">
            <a class="nav-link mx-2 active" aria-current="page" href="/create">Create</a>
          </li>
          <li class="nav-item">
            <a class="nav-link mx-2" href="/">Builds</a>
          </li>
          <li v-if="user.upload_license_allowed" class="nav-item">
            <a class="nav-link mx-2" href="/upload/license">Upload license</a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link mx-2 dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              {{ user.email }}
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <li><a class="dropdown-item" href="#">Settings</a></li>
              <li><a class="dropdown-item" href="#">Profile</a></li>
              <li><hr class="dropdown-divider"></li>
              <li><a class="dropdown-item" href="#" @click.prevent="logout">Log out</a></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import axios from '@/services/axios-config.js';

export default {
  name: 'Navbar',
  data() {
    return {
      // any navbar specific data
    };
  },
  methods: {
    async logout() {
      try {
        const response = await axios.get('/logout');
        if (response.data.status === 'success') {
          this.$store.dispatch('logout');
          this.$router.push('/login');
        } else {
          // Handle unsuccessful logout
          console.error('Logout failed');
        }
      } catch (error) {
        console.error('Error during logout:', error);
      }
    }
  },
  computed: {
    user() {
      return this.$store.state.user ? this.$store.state.user : null;
    }
  }
}

</script>

<style scoped>
/* Your navbar styles here */
</style>
