import { createStore } from 'vuex';

export default createStore({
    state() {
        return {
            user: {
                email: null,
            }
        };
    },
    mutations: {
        setUserEmail(state, user_email) {
            state.user.email = user_email;
        },
        clearUser(state) {
            state.user = {};
        },
        setUserProperty(state, { property, value }) {
            state.user[property] = value;
        }
    },
    actions: {
        authenticateUser({ commit }, data) {
            if (data.is_authenticated) {
                commit('setUserEmail', data.user.email);
                commit('setUserProperty', { property: 'upload_license_allowed', value: data.upload_license_allowed });
            }
        },
        logout({ commit }) {
            commit('clearUser');
        }
    },
    getters: {
        user: state => state.user,
    }
});
