import axios from 'axios';

const instance = axios.create({
    // Production
    baseURL: '/api',
    // Development
    //baseURL: 'http://localhost:8000/api',
    withCredentials: true,
});

export default instance;
