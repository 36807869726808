<template>
  <Navbar/>
  <loading
      id="loader"
      class="vl-parent"
      v-model:active="isLoading"
      :is-full-page="true"
      color="#007bff"
      :height=128
      :width=128
  />
  <div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">Upload New License File</div>
          <div class="card-body">
            <form @submit.prevent="uploadFile">
              <div class="form-group">
                <label for="file">Choose File:</label>
                <input
                    id="file"
                    type="file"
                    class="form-control-file mt-2"
                    @change="onFileChange"/>
              </div>
              <button type="submit" class="btn btn-primary mt-3">Upload</button>
              <div class="mt-3" v-if="uploadedFileUrl">
                <a v-bind:href="uploadedFileUrl">{{ uploadedFileUrl }}</a>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="mt-5" v-if="uploadedLicenses.length">
        <table class="table">
          <thead>
          <tr>
            <th>Date</th>
            <th>User</th>
            <th>Uploaded License</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="license in uploadedLicenses" :key="license.url">
            <td>{{ license.date }}</td>
            <td>{{ license.user }}</td>
            <td><a v-bind:href="license.url">{{ license.url }}</a></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import axios from '@/services/axios-config.js';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

export default {
  name: 'UploadLicense',
  components: {
    Navbar,
    Loading
  },
  data() {
    return {
      isLoading: false,
      selectedFile: null,
      uploadedFileUrl: null,
      uploadedLicenses: [],
    };
  },
  methods: {
    async loadUploadsList() {
      const response = await axios.get('/upload/license');
      this.uploadedLicenses = response.data.uploads_history;
    },

    onFileChange(e) {
      this.selectedFile = e.target.files[0];
    },
    async uploadFile() {
      this.isLoading = true;
      const formData = new FormData();
      formData.append("file", this.selectedFile);

      try {
        const response = await axios.post("/upload/license", formData, {
          headers: {
            'Content-Type': 'multipart/form-data', // Set the content type for FormData
          },
        });

        if (response.status === 201) {
          // Save the uploaded file URL
          this.uploadedFileUrl = response.data.url;
          // Reload the list of uploaded files
          this.loadUploadsList();
        } else {
          throw new Error("Error in upload");
        }
      } catch (err) {
        console.error("Error occurred while uploading file", err);
      }
      this.isLoading = false;
    }
  },
  mounted() {
    this.loadUploadsList();
  },
}
</script>

<style scoped>
#loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
